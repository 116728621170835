import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiShare } from "react-icons/fi";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { useAppContext } from "../../../context";
import { ShareContent } from "../../index";
import style from "./Share.module.css";

const ShareBtn = ({ overlay, setOverlay, sharedPalette }) => {
  const { t } = useTranslation();
  const { colormatch } = useAppContext();
  const [options, setOptions] = useState({
    image: false,
    export: null,
  });

  const popover = (
    <Popover id="popover-share">
      <Popover.Body>
        <ShareContent
          window={overlay}
          options={options}
          setOptions={setOptions}
          sharedPalette={sharedPalette}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={sharedPalette ? "bottom-start" : "bottom"}
      overlay={popover}
      containerPadding={20}
      rootClose
      onExit={() => setOverlay({ share: false })}
    >
      <Button
        variant="secondary"
        className={`${sharedPalette ? style.sharedPaletteActionBtn : style.actionBtn} ${overlay && style.onTopBtn} `}
        disabled={
          !sharedPalette ? (colormatch.length > 0 ? false : true) : false
        }
        onClick={() => setOverlay({ share: true })}
      >
        <FiShare /> {t("Share")}
      </Button>
    </OverlayTrigger>
  );
};

export default ShareBtn;
