import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col, Button, Card } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context";
import { Toaster } from "sonner";
import { Alert, useUpdateUser } from "../../utils";
import { SubscriptionStatusListener as SubscriptionStatus } from "./index";
import { Inputs, ReusableForm } from "../../components";
import style from "./Profile.module.css";
import { useTranslation } from "react-i18next";
import { subscribeToPro } from "../../utils/auxiliarFunctions";

const Profile = () => {
  const updateUser = useUpdateUser();
  const { user } = useAppContext();
  const {t, i18n} = useTranslation();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (user) {
      console.log(user);
      setEmail(user.email);
      setFirstName(user.name);
      setLastName(user.lastName);
      if (user.company) {
        setCompany(user.company);
      }
    }
  }, [user]);

  const handleManageBillingInformation = async () => {
    /* console.log(user.stripeCheckoutSessionId); */
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL_V2}/api/stripe/create-portal-session`,
        {
          /* axios.post('http://localhost:4242/create-portal-session', { */
          session_id: user.stripeCheckoutSessionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log(error);
        Alert.error("An error has occurred. Please contact support");
      });
  };

  const handleSubscribe = async () => {
    subscribeToPro(user.email, i18n.language);
  };

  const handleChangeProfileData = (e) => {
    if (e.target.name === "name") {
      setFirstName(e.target.value);
    }

    if (e.target.name === "lastName") {
      setLastName(e.target.value);
    }

    if (e.target.name === "company") {
      setCompany(e.target.value);
    }
  };

  const handleSwitchEditMode = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const lastName = e.target.lastName.value;
    const company = e.target.company.value;

    if (name === "" || lastName === "") {
      return Alert.error("Complete all fields");
    }

    const usuarioEditado = {
      name: name,
      lastName: lastName,
      company: company,
    };

    try {
      updateUser.mutate({
        userId: user.email,
        user: usuarioEditado,
      });
      Alert.success("Changes saved successfully");
      setEditMode(false);
    } catch (error) {
      Alert.error("An error has occurred. Try again later");
      console.log(error);
    }
  };

  return (
    <Container>
      <Row className="m-0 p-0 py-4 justify-content-center">
        <Col xs={12}>
          <h1 className={style.title}>
            {t("Hi")}
            {user ? (
              `, ${user.name}!`
            ) : (
              <Spinner animation="grow" size="sm" variant="dark" />
            )}
          </h1>
        </Col>
      </Row>
      <Row className="justify-content-around">
        <Col xs={12} md={5} lg={6}>
          {user ? (
            <>
              <h4 className={style.cardTitle}>{t("Account Information")}</h4>
              <Card className={style.card}>
                <ReusableForm onSubmit={handleSubmit}>
                  <Inputs.FormControl
                    label="Email Address"
                    name="email"
                    value={email}
                    type="email"
                    placeholder="Enter email"
                    className={`${style.disabledInput} mb-4`}
                    disabled={true}
                  />
                  <Row>
                    <Col>
                      <Inputs.FormControl
                        label="First Name"
                        name="name"
                        value={firstName}
                        onChange={handleChangeProfileData}
                        type="text"
                        placeholder="Enter First Name"
                        className={`${!editMode && style.disabledInput} mb-4`}
                        disabled={!editMode}
                      />
                    </Col>
                    <Col>
                      <Inputs.FormControl
                        label="Last Name"
                        name="lastName"
                        value={lastName}
                        onChange={handleChangeProfileData}
                        type="text"
                        placeholder="Enter Last Name"
                        className={`${!editMode && style.disabledInput} mb-4`}
                        disabled={!editMode}
                      />
                    </Col>
                  </Row>
                  <Inputs.FormControl
                    label="Company (optional)"
                    name="company"
                    value={company}
                    onChange={handleChangeProfileData}
                    type="text"
                    placeholder="Enter Company Name"
                    className={`${!editMode && style.disabledInput} mb-4`}
                    disabled={!editMode}
                  />
                  <Button
                    variant="outline-dark"
                    type={!editMode ? "button" : "submit"}
                    onClick={(e) => !editMode && handleSwitchEditMode(e)}
                    className={style.editSaveBtn}
                  >
                    {!editMode ? t("Edit Profile Information") : t("Save changes")}
                  </Button>
                </ReusableForm>
              </Card>
            </>
          ) : (
            <Spinner animation="grow" size="sm" variant="dark" />
          )}
        </Col>
        {user &&
          <>
            <Col>
              <h4 className={style.cardTitle}>{t("Subscription Information")}</h4>
              <Card className={style.card}>
                <div className={style.editPaymentInfoContainer}>
                  <div className={style.stripeSubscriptionContainer}>
                    <p>{t("Subscription status")}: </p>
                    <SubscriptionStatus userId={user?.email} />
                  </div>
                    <Button
                      id="checkout-and-portal-button"
                      type="button"
                      onClick={user?.stripeSubscriptionStatus != 'canceled' && user?.stripeSubscriptionStatus != '' ? handleManageBillingInformation : handleSubscribe}
                      variant="outline-dark"
                      className={style.editPaymentInfoBtn}
                    >
                      {user?.stripeSubscriptionStatus != 'canceled' && user?.stripeSubscriptionStatus != '' ? t('Edit Payment Information') : t('Upgrade')}
                    </Button>
                </div>
                    
                <small
                  className={style.cancelSubscriptionText}
                  onClick={handleManageBillingInformation}
                >
                  {user?.stripeCheckoutSessionId !== "" && user?.stripeSubscriptionStatus != 'canceled' && t('Cancel Subscription')}
                </small>
              </Card>
            </Col>
          </>
        }
        <Toaster richColors position="bottom-center" />
      </Row>
    </Container>
  );
};

export default Profile;
