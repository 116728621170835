import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
/* import { GoLink } from "react-icons/go"; */
import { GrLink } from "react-icons/gr";
/* import { FiSend } from "react-icons/fi"; */
import { FaFacebookMessenger } from "react-icons/fa";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  collection,
  doc,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { app } from "../../../firebase";
import { useAppContext } from "../../../context";
import { CopyLinkButton, Inputs, NavLinkWithLocale } from "../../index";
import { Alert, imagesMapToUpload, useAddPalette } from "../../../utils";
import style from "./Share.module.css";
import { useScreenSize } from "../../../utils";

const db = getFirestore(app);

const ShareContent = ({
  window: popUp,
  options,
  setOptions,
  sharedPalette,
}) => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { colormatch, images, user } = useAppContext();
  const { mutate: addPalette, isLoading } = useAddPalette(true);
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const [paletteName, setPaletteName] = useState("");
  const [paletteId, setPaletteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [paletteGenerated, setPaletteGenerated] = useState(false);
  const { paletteId: paletteIdFromParams } = useParams();
  const [btn, setBtn] = useState("");
  const screenSize = useScreenSize();

  useEffect(() => {
    if (!popUp) {
      setOptions({ image: false, export: null });
      setPaletteName("");
      setLinkCopied(false);
    }
    if (!sharedPalette && paletteIdFromParams) {
      setPaletteId(paletteIdFromParams);
    }
    if (sharedPalette) setShowMedia(true);
  }, [
    popUp,
    setOptions,
    location.pathname,
    sharedPalette,
    paletteIdFromParams,
  ]);

  useEffect(() => {
    setPaletteGenerated(false);
  }, [options]);

  const handleSharePalette = useCallback(() => {
    const newDocRef = doc(collection(db, "sharedPalettes"));
    const newId = newDocRef.id;
    return new Promise((resolve, reject) => {
      imagesMapToUpload({
        images,
        setProgress,
        collection: "sharedPalettes",
        id: newId,
      })
        .then((imagesWithURL) => {
          const paletteToSave = {
            images: imagesWithURL,
            colors: colormatch,
            showImage: options.image,
            type: options.export,
            createdAt: serverTimestamp(),
            user: user,
            paletteName: paletteName,
          };

          return addPalette(
            { palette: paletteToSave, id: newId },
            {
              onSuccess: (docId) => {
                setPaletteId(docId);
                resolve(docId);
              },
              onError: (error) => {
                Alert.error("Error sharing palette, try again.");
                reject(error);
              },
            }
          );
        })
        .catch((error) => {
          Alert.error("Error processing images, try again.");
          reject(error);
        })
        .finally(() => {
          setPaletteGenerated(true);
        });
    });
  }, [
    addPalette,
    options.export,
    options.image,
    paletteName,
    colormatch,
    images,
    user,
  ]);

  const copyLinkOfSavedPalette = useCallback(async () => {
    setLoading(true);
    try {
      let url = "";
      if (sharedPalette) {
        url = `${window.location?.protocol}//${window.location?.host}${location.pathname}`;
      } else if (!paletteGenerated) {
        let id = paletteId;
        id = await handleSharePalette();
        url = `https://colormatch.tech/${i18n.language}/share/${id}`;
      } else {
        url = `https://colormatch.tech/${i18n.language}/share/${paletteId}`;
      }

      // Copiar al portapapeles si tenemos una URL
      if (url) {
        await navigator.clipboard.writeText(url);
        if (sharedPalette) {
          Alert.success(`Link Copied!`);
        } else {
          Alert.success(`Link Copied! The link will expire in 7 days`);
        }
      }
      setLoading(false);
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 4000);
    } catch (error) {
      console.log("Error al copiar el enlace: ", error);
    }
  }, [
    paletteId,
    handleSharePalette,
    sharedPalette,
    i18n.language,
    location?.pathname,
    paletteGenerated,
  ]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPaletteName(value);
  };

  const handleShareClick = () => {
    if (sharedPalette) {
      setShowMedia(!showMedia);
    } else if (!paletteGenerated) {
      handleSharePalette();
      setBtn("share");
    } else {
      setShowMedia(!showMedia);
    }
  };

  const media = [
    {
      id: "copyLink",
      Component: CopyLinkButton,
      Icon: GrLink,
      extraProps: {
        functionOnClick: copyLinkOfSavedPalette,
      },
      iconClassName: style.copyLinkIcon,
      size: screenSize < 992 ? "2rem" : "1.5rem",
      title: "Copy Link",
    },
    {
      id: "email",
      Component: EmailShareButton,
      Icon: EmailIcon,
      size: screenSize < 992 ? "3rem" : "2.5rem",
      title: "Email",
    },
    {
      id: "whatsapp",
      Component: WhatsappShareButton,
      Icon: WhatsappIcon,
      size: screenSize < 992 ? "3rem" : "2.5rem",
      title: "WhatsApp",
    },
    {
      id: "messenger",
      Component: FacebookMessengerShareButton,
      Icon: FaFacebookMessenger,
      extraProps: { appId: "521270401588372" },
      iconClassName: style.fbMessengerIcon,
      size: screenSize < 992 ? "3rem" : "2.5rem",
      title: "Messenger",
    },
    {
      id: "facebook",
      Component: FacebookShareButton,
      Icon: FacebookIcon,
      size: screenSize < 992 ? "3rem" : "2.5rem",
      title: "Facebook",
    },
  ];

  return (
    <>
      {!sharedPalette && (
        <Row>
          <Col xs={12}>
            <Form.Check
              className={style.optionsLabel}
              id="client view"
              label={<p>{t("Share Palette with Client")}</p>}
              name="group1"
              type="radio"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  export: "client",
                }))
              }
              checked={options.export === "client"}
            />
            {options.export === 'client' && (
              <Inputs.FormControl
                label={t("Palette/Event Name:")}
                name="paletteName"
                value={paletteName}
                onChange={handleInputChange}
                type="text"
                placeholder={t("Alana's Baby Shower")}
                className={`${style.shareTextInputs} mb-3`}
                customClass={style.downloadTextInputs}
                autoComplete="off"
              />
            )}
            <Form.Check
              className={style.optionsLabel}
              id="internal view"
              label={<p>{t("Share Palette with Balloon Artist")}</p>}
              name="group1"
              type="radio"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  export: "internal",
                }))
              }
              checked={options.export === "internal"}
            />
            {options.export === 'internal' && (
              <Inputs.FormControl
                label={t("Palette/Event Name:")}
                name="paletteName"
                value={paletteName}
                onChange={handleInputChange}
                type="text"
                placeholder={t("Alana's Baby Shower")}
                className={`${style.downloadTextInputs} mb-3`}
                customClass={style.downloadTextInputs}
                autoComplete="off"
              />
            )}
          </Col>
          {/* <Inputs.FormControl
            label={t("Palette/Event Name:")}
            name="paletteName"
            value={paletteName}
            onChange={handleInputChange}
            type="text"
            placeholder={t("Alana's Baby Shower")}
            className={`${style.shareTextInputs} mb-3`}
          /> */}
          <hr className="mb-3 mt-1"/>
          <Col xs={12} className="mb-2">
            <Form.Check
              className={style.optionsLabel}
              name="image"
              checked={options.image}
              inline
              id="Include Inspiration Image"
              label={t("Include Inspiration Images")}
              type="checkbox"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  image: !prevState.image,
                }))
              }
            />
          </Col>
        </Row>
      )}
      <Row style={{ minWidth: 250 }}>
        {/* <Col xs={6}>
          <Button
            variant="dark"
            className={`p-2 ${style.shareBtn}`}
            onClick={handleCopyClick}
            disabled={
              (!sharedPalette && !options.export) || isLoading || loading
            }
          >
            {loading || isLoading ? (
              t("Generating...")
            ) : linkCopied && btn === "copy" ? (
              <>{t("Link Copied!")}</>
            ) : (
              <>
                <GoLink /> {t("Copy Link")}
              </>
            )}
          </Button>
        </Col> */}
        {!sharedPalette && (
          <Col xs={12}>
            <Button
              variant="dark"
              className={`p-2 ${style.shareBtn}`}
              onClick={handleShareClick}
              disabled={
                (!sharedPalette && !options.export ) || isLoading || loading || !paletteName
              }
            >
              {loading || isLoading ? (
                t("Generating...")
              ) : (
                <>
                  {t("Share")}
                </>
              )}
            </Button>
          </Col>
        )}
      </Row>
      {(showMedia || (paletteId && paletteGenerated && btn === "share")) && (
        <Row
          className={`mt-3 justify-content-around ${
            !sharedPalette && style.mediaContainer
          }`}
        >
          {media.map(
            ({
              id,
              Component,
              Icon,
              extraProps,
              iconClassName,
              size,
              title,
            }) => (
              <Col key={id} xs="2" className={style.mediaCol}>
                <Component
                  url={`https://colormatch.tech/${i18n.language}/share/${paletteId}`}
                  {...extraProps}
                >
                  <Icon size={size} round className={iconClassName || ""} />
                </Component>
                <p>{title}</p>
              </Col>
            )
          )}
        </Row>
      )}
      {paletteGenerated && paletteId && (
        <Col className="mt-2" xs="auto">
          <NavLinkWithLocale
            target="_blank"
            to={`/share/${paletteId}`}
            className={style.optionsSubLabel}
          >
            {`https://colormatch.tech/${i18n.language}/share/${paletteId}`}
          </NavLinkWithLocale>
        </Col>
      )}
    </>
  );
};

export default ShareContent;
