import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { categories } from "../../utils";
import style from "./Filters.module.css";

const FilterByCategory = ({ categoriesSelected, handleChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();

  const handleApplyFilters = () => {
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <Col className="mb-3 p-0" xs={6} sm={4} md={3} xl={2}>
      <DropdownBtn
        title={t("Filter by Finish")}
        variant="secondary"
        filterDropdownBtn={true}
        showDropdown={showDropdown}
        handleApplyFilters={handleApplyFilters}
        toggleDropdown={toggleDropdown}
      >
        {categories.map((category, index) => (
          <Dropdown.Item
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(category);
            }}
          >
            <Form.Check
              type="checkbox"
              checked={categoriesSelected[category]}
              name={category}
              label={category}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(category);
              }}
              onChange={() => {}}
            />
          </Dropdown.Item>
        ))}
        <div className="d-flex justify-content-end px-2">
          <Button
            className={`px-4 ${style.applyFiltersBtn}`}
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </div>
      </DropdownBtn>
    </Col>
  );
};

export default FilterByCategory;
