import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import {
  sizes,
  useBalloonsV2,
  useFilterByCategory,
  useFilterByColorCategory,
  useFilterByBrand,
  useSizeFilter,
} from "../../utils";
import {
  AutoCompleteFilters,
  BalloonOption,
  FilterByBrand,
  FilterByCategory,
  FilterByColorCategory,
  FilterBySize,
  SelectedOption,
} from "../index";
import style from "./Filters.module.css";

const Filters = ({ selected, setSelected, addBalloonsModal }) => {
  const { data: balloonsData, isLoading: loadingBalloons } = useBalloonsV2();
  const sizesMemo = useMemo(() => sizes, []);
  const { t } = useTranslation();

  const { brandsSelected, handleBrandsChange, filteredBrands } =
    useFilterByBrand(balloonsData);
  const { sizesSelected, handleSizesChange, filteredSizes } =
    useSizeFilter(balloonsData);
  const { categoriesSelected, handleCategoryChange, filteredCategories } =
    useFilterByCategory(balloonsData);
  const {
    colorCategoriesSelected,
    handleColorCategoryChange,
    filteredColorCategories,
  } = useFilterByColorCategory(balloonsData);

  const options = useMemo(() => {
    let result = balloonsData;

    // Aplicar filtro de marcas si se ha filtrado algo
    if (Object.values(brandsSelected).some((selected) => !selected)) {
      result = filteredBrands;
    }

    // Aplicar filtro de tamaños si se ha filtrado algo
    if (Object.values(sizesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredSizes.includes(item));
    }

    // Aplicar filtro de categorías si se ha filtrado algo
    if (Object.values(categoriesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredCategories.includes(item));
    }

    // Aplicar filtro de categorías de color si se ha filtrado algo
    if (Object.values(colorCategoriesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredColorCategories.includes(item));
    }

    return result;
  }, [
    balloonsData,
    brandsSelected,
    filteredBrands,
    sizesSelected,
    filteredSizes,
    categoriesSelected,
    filteredCategories,
    colorCategoriesSelected,
    filteredColorCategories,
  ]);

  const renderBalloonOption = (option) => (
    <BalloonOption option={option} sizes={sizesMemo} />
  );

  return (
    <Row className="m-0 p-0">
      {/* <FilterByBrand
        brandsSelected={brandsSelected}
        handleChange={handleBrandsChange}
      />
      <FilterBySize
        sizesSelected={sizesSelected}
        handleChange={handleSizesChange}
      />
      <FilterByCategory
        categoriesSelected={categoriesSelected}
        handleChange={handleCategoryChange}
      />
      <FilterByColorCategory
        colorCategoriesSelected={colorCategoriesSelected}
        handleChange={handleColorCategoryChange}
      /> */}
      <Col xs={12}>
        <AutoCompleteFilters
          /* title={t("balloons")} */
          options={options}
          isLoading={loadingBalloons}
          placeholder={t("Search...")}
          renderOption={renderBalloonOption}
          selected={selected}
          setSelected={setSelected}
          className={style.autocompleteContainer}
          addBalloonsModal={addBalloonsModal && true}
        />
      </Col>
      <Row className="mt-2">
        {selected.map((option, index) => (
          <SelectedOption
            key={index}
            id={option.id}
            displayName={option.displayName}
            name={option.name}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
      </Row>
    </Row>
  );
};

export default Filters;
