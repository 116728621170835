import React from "react";
import { useTranslation } from "react-i18next";
import { MdDone } from "react-icons/md";
import { Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../../context";
import { NavLinkWithLocale } from "../../../components";
import style from "./PlanSquare.module.css";
import axios from "axios";
import { subscribeToPro } from "../../../utils/auxiliarFunctions";

const PlanSquare = ({
  title,
  price,
  subtitle,
  planInfo,
  planBodyTitle,
  advantages,
  button
}) => {
  const { user } = useAppContext();
  const { t, i18n } = useTranslation();

  const handleSubscribe = async () => {
    subscribeToPro(false, i18n.language);
  };

  return (
    <Col xs={10} md={5} lg={4} className={style.planSquare}>
      <Row>
        <Col xs={12}>
          <h2>{title}</h2>
          <section className={style.planSubtitleSec}>
            <p>{price}</p> <span>{subtitle}</span>
          </section>
          <br />
          <p className={style.planInfo}>{planInfo}</p>
          {title === "Basic" && !user && (
            <NavLinkWithLocale to="/createAccount">
              <Button variant="secondary" size="lg" className={style.signUpBtn}>
                {t("GET STARTED FOR FREE")}
              </Button>
            </NavLinkWithLocale>
          )}
          {title === "Pro" && (!user?.stripeSubscriptionStatus || user?.stripeSubscriptionStatus == 'canceled') && (
            <>
              <Button variant="secondary" size="lg" className={style.signUpBtn} onClick={handleSubscribe}>
                {t("SIGN UP FOR PRO")}
              </Button>
              <br/>
              <span className={style.cancelTxt}>Cancel at any time</span>
            </>
          )}
        </Col>
      </Row>
      <hr className={style.hr} />
      <Row>
        <Col className="my-3" xs={12}>
          <h4 className={style.planBodyTitle}>{planBodyTitle}</h4>
        </Col>
        {advantages.map((item, index) => (
          <Col className="mb-3" xs={12} key={index}>
            <Row>
              <Col xs={12} className={`m-0 p-0 ${style.planBodyTextCol}`}>
                <Row className="align-items-start">
                  <Col xs={1}>
                    <MdDone className={style.icon} />
                  </Col>
                  <Col xs={10}>
                    <h4 className={style.planBodyText}>{item.text}</h4>
                  </Col>
                </Row>
              </Col>
              {item.subtitle && (
                <Col className={style.planSubtitle} xs={12}>
                  <p className={style.planBodySubtext}>{item.subtitle}</p>
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default PlanSquare;
