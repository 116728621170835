import React, {useState} from "react";
import { Row, Col, Form } from "react-bootstrap";
import { CanvasWithZoom, CanvasWithZoomV2 } from "../index";
import style from "./ColorPicker.module.css";

const ColorPicker = ({
  brightness,
  index,
  onSelect,
  selectedColor,
  setSelectedColor,
}) => {
  const [pickerType, setPickerType] = useState('default');

  const handleSelectPicker = (e)=>{
    //console.log(e.target.value);
    setPickerType(e.target.value);
  }

  return (
    <Row className={style.imageContainer}>
      <Col>
        <Form.Select onChange={handleSelectPicker} className="mb-4">
          <option>Select picker</option>
          <option value="default">Pixel perfect</option>
          <option value="hsvFilter">Pixel perfect (HSV update)</option>
          <option value="10">10x10 predominant</option>
          <option value="5">5x5 predominant</option>
        </Form.Select>
        {pickerType == 'default' || pickerType == 'hsvFilter' ? 
          <CanvasWithZoom
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            brightness={brightness}
            index={index}
            onSelect={onSelect}
            picker={pickerType}
          />
        :
          <CanvasWithZoomV2
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            brightness={brightness}
            index={index}
            onSelect={onSelect}
            picker={pickerType}
          />
      }
      </Col>
    </Row>
  );
};

export default ColorPicker;
