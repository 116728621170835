import React, { useCallback, useState } from "react";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context";
import { ColorCard, Dnd, ModalWindow, ViewDetailsRow } from "../index";
import { colorMatchFn, subscribeToPro, useScreenSize } from "../../utils";
import style from "./CardsContainer.module.css";

const CardsContainerWithRangesOld = ({ sortable, setSelectedColor }) => {
  const { t, i18n } = useTranslation();
  const screenSize = useScreenSize();
  const { user, colormatch, setColormatch, loadingColorMatch, setLoadingColorMatch } = useAppContext();
  const [modal, setModal] = useState({
    show: false,
    color: {},
  });
  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const {
    closerColor,
    primaryMatchRange,
    secondMatchRange,
    thirdMatchRange,
    fourthMatchRange,
    fifthMatchRange,
  } = modal.color;
  //const { brandsSelected, filteredBrands, handleBrandsChange } = useFilterByBrand(closerColors);

  /* let listOfCloserColors = [
    ...(Array.isArray(closerColors) ? closerColors : []),
    ...(Array.isArray(secondaryMatches) ? secondaryMatches : []),
    ...(Array.isArray(thirdMatchRange) ? thirdMatchRange : []),
    ...(Array.isArray(fourthMatchRange) ? fourthMatchRange : []),
    ...(Array.isArray(fifthMatchRange) ? fifthMatchRange : []),
  ]; */

  /* let listOfSecondaryColors;
  if (filteredBrandsSecondary) {
    listOfSecondaryColors = [...filteredBrandsSecondary];
  } */

  const handleColorMatch = useCallback(
    (elementSelected, index) => {
      colorMatchFn(colormatch, setColormatch, setLoadingColorMatch, elementSelected, userPro, index);
    },
    [colormatch, setColormatch, userPro]
  );

  const hideModal = useCallback(() => {
    setModal((prevModal) => ({ ...prevModal, show: false }));
  }, []);

  const replaceColors = useCallback(
    (color) => {
      const index = colormatch.findIndex(
        (item) => item.closerColor.color === closerColor.color
      );
      handleColorMatch(color.color, { insert: index });
      hideModal();
    },
    [colormatch, closerColor?.color, handleColorMatch, hideModal]
  );

  const addColor = useCallback(
    (color) => {
      handleColorMatch(color.color);
      hideModal();
    },
    [handleColorMatch, hideModal]
  );

  const handleSubscribe = async () => {
    subscribeToPro(user.email, i18n.language);
  };

  // seteo el ultimo elemento del array como login para mostrar el spinner
  const itemsToRender =  loadingColorMatch
  ? [...colormatch, "loading"]
  :  colormatch;

  const renderCloserColors = () => {
    return (
      <>
        {/* <h4 className={style.listColorTitle}>Selected:</h4> */}
        {screenSize < 992 && (
          <h4 className={`mb-4 ${style.listColorTitle}`}>{t("Selected")}:</h4>
        )}
        <ViewDetailsRow
          selected
          key={closerColor?.color}
          item={closerColor}
          addColor={addColor}
          replaceColors={replaceColors}
          buttons={true}
          closer={true}
        />
        <div className="mt-4">
          <h4 className={style.listColorTitle}>{t("Alternative options")}:</h4>
        </div>
      </>
    );
  };

  //console.log("COLORMATCH: ", colormatch);

  return (
    <>
      <Row
        id="cardsContainer"
        xs={3}
        md={3}
        lg={4}
        xl={4}
        className={`row-gap-4 ${screenSize <= 768 && "mx-sm-4"}`}
      >
        {sortable ? (
          <Dnd data={colormatch} setData={setColormatch}>
            {colormatch.map((color, index) => (
              <ColorCard
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
              />
            ))}
          </Dnd>
        ) : (
          itemsToRender.map((color, index) => (
            color === "loading" ?
              <div className="d-flex justify-content-center mt-5">
                <Spinner animation="grow" key={index} />
              </div>
            :    
              <ColorCard
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
                setSelectedColor={setSelectedColor}
              />
          ))
        )}
      </Row>
      <ModalWindow
        show={modal.show}
        onHide={hideModal}
        closeButton={true}
        centered={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        title={`${t("Selected")}:`}
        viewDetailsModal={true}
        id="view-more-modal"
        /* header={
          <Row>
            <Col className="mb-2" xs={12}>
              <p className={style.title}>
                {t(
                  "Color swatches are presented from the closest match to the least."
                )}
              </p>
            </Col>
            <Col xs={12}>
              <FilterByBrand
                brandsSelected={brandsSelectedCloser}
                handleChange={handleBrandsChangeCloser}
              />
            </Col>
          </Row>
        } */
        body={
          <Row>
            <Col className={`p-0 ${style.infoContainer}`}>
              {renderCloserColors()}

              {primaryMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>0-2</p>
                </>
              )}
              {primaryMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}

              {secondMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>2-5</p>
                </>
              )}
              {secondMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}

              {thirdMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>5-10</p>
                </>
              )}
              {thirdMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}

              {fourthMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>10-15</p>
                </>
              )}
              {fourthMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}

              {fifthMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>15-20</p>
                </>
              )}
              {fifthMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}
            </Col>
            <Alert show={!userPro} className={style.alert}>
              <Row className="align-items-center justify-content-around">
                <Col>{t("Go Pro to unlock alternative options.")}</Col>
                <Col xs={4}>
                  <Button
                    onClick={handleSubscribe}
                    className={style.actionBtnUpgrade}
                  >
                    {t("Upgrade")}
                  </Button>
                </Col>
              </Row>
            </Alert>
          </Row>
        }
      />
      {screenSize <= 992 && (
        <Row>
          <Col className="justify-content-end d-flex p-4">
            <p className={style.versionTxt}>
              Beta version 1.1.10. {t("versionTxt")}
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CardsContainerWithRangesOld;
