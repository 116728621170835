import React from "react";
import { Container, Row } from "react-bootstrap";
import { SearchTool } from "../../components";

const Search = () => {
  return (
    <Container>
      <Row>
        <SearchTool />
      </Row>
    </Container>
  );
};

export default Search;
