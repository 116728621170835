import React, { useMemo } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import style from "../Form.module.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";

const AutoCompleteFilters = ({
  optionalTitle,
  options,
  isLoading,
  selected,
  setSelected,
  placeholder,
  renderOption,
  addBalloonsModal,
}) => {
  const { t } = useTranslation();
  const filters = useMemo(
    () => ["brand", "category", "name", "displayName", "colorCategories"],
    []
  );

  const handleAutocompleteChange = (selectedItems) => {
    if (!addBalloonsModal) {
      setSelected((prevState) =>
        prevState.filter((item) => item.name === selectedItems.name)
      );
    }
    setSelected((prevState) => [...prevState, ...selectedItems]);
  };

  /* console.log(selected); */

  const customFilterBy = (option, props) => {
    const filterText = props.text.toLowerCase();
    return filters.some((field) => {
      const value = option[field];
      if (Array.isArray(value)) {
        return value.some((item) => item.toLowerCase().includes(filterText));
      }
      return (
        typeof value === "string" && value.toLowerCase().includes(filterText)
      );
    });
  };

  let optionsOrdered = options;

  if (addBalloonsModal) {
    const orderPinksFirst = (data) => {
      return data.sort((a, b) => {
        const aEsRosa = a.colorCategories.includes("Pinks");
        const bEsRosa = b.colorCategories.includes("Pinks");

        // Si a es rosa y b no, a debe ir primero
        if (aEsRosa && !bEsRosa) return -1;
        // Si b es rosa y a no, b debe ir primero
        if (!aEsRosa && bEsRosa) return 1;
        // Si ambos son rosas o ninguno, mantener el orden relativo
        return 0;
      });
    };

    optionsOrdered = orderPinksFirst(options ? options : []);
  }

  return (
    <Form.Group>
      <Form.Label>{optionalTitle && `${optionalTitle}`}</Form.Label>
      <Typeahead
        id="filtering-autocomplete"
        labelKey={(option) =>
          `${option.displayName ? option.displayName : option.name} ${
            option.displayName ? `- ${option.name}` : ""
          }`
        }
        multiple
        onChange={handleAutocompleteChange}
        options={optionsOrdered || []}
        placeholder={placeholder}
        selected={!addBalloonsModal ? selected : []}
        isLoading={isLoading}
        filterBy={customFilterBy}
        disabled={isLoading}
        paginate
        paginationText="Load more results"
        maxResults={25}
        renderMenuItemChildren={(option) => (
          <Row className={`pb-3 align-items-center ${style.optionRow}`}>
            {/* {(option.image || option.imageURL) && (
              <Col xs={2} lg={1}>
                <Image
                  src={
                    option.image ? option.image[0].imageURL : option.imageURL
                  }
                  style={{ aspectRatio: "4/5" }}
                  width="100%"
                  alt="balloonImg"
                />
              </Col>
            )} */}
            {option.hexColor && (
              <Col xs={2} lg={1}>
                <Image
                  src={
                    option.image ? option.image[0].imageURL : option.imageURL
                  }
                  style={{ aspectRatio: "4/5" }}
                  width="100%"
                  alt="balloonImg"
                />
              </Col>
            )}
            <Col xs={option.hexColor ? 10 : 12} lg={option.hexColor ? 11 : 12}>
              {renderOption(option)}
            </Col>
          </Row>
        )}
      />
    </Form.Group>
  );
};

export default AutoCompleteFilters;
