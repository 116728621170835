import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdDone } from "react-icons/md";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context";
import { Collapse, Footer, Slider } from "../../components";
import { useScreenSize } from "../../utils";
import { PlanSquare } from "./index";
import style from "./Pricing.module.css";
import { TextsSlider } from "./TextsSlider";

const Pricing = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { t } = useTranslation();

  const freeAdvantages = [
    { text: t("Match balloons to your inspiration") },
    { text: t("Find the brand of your preferred colors") },
    { text: t("Check balloon size availability") },
    { text: t("Manually create color palettes") },
    { text: t("Access a large library of latex balloons") },
  ];

  const proAdvantages = [
    { text: t("Discover alternate matches") },
    { text: t("Use brand preference filters") },
    { text: t("Save and edit your color palettes") },
    { text: t("Share your color palette with clients") },
    { text: t("Share your color palette with co-workers") },
    { text: t("Download your work") },
  ];

  const paragraphs = [
    {
      title: t("Inspiration Upload"),
      text: t(
        "Upload images to instantly generate matching balloon color palettes."
      ),
    },
    {
      title: t("Customizable Palettes"),
      text: t(
        "Manually add, remove, or replace items in your palette to fit your design needs."
      ),
    },
    {
      title: t("Sourcing Details"),
      text: t(
        "View detailed sourcing information including brands and available sizes."
      ),
    },
    {
      title: t("User-Friendly Interface"),
      text: t(
        "Enjoy a seamless user experience tailored for balloon professionals."
      ),
    },
    {
      title: t("Time-Saving Tool"),
      text: t(
        "Save hours on color matching and sourcing with our automated tools."
      ),
    },
    {
      title: t("Support"),
      text: t(
        "Get answers and guidance whenever you need-our team is here to help."
      ),
    },
    /* {
      title: "Alternative Brands",
      text: "Find and compare alternative balloon brands and sizes for your designs.",
    },
    {
      title: "Accessory Database",
      text: "Access a comprehensive database of popular confetti, tassel, and foil accessories for your balloon designs.",
    }, */
  ];

  const collapses = [
    {
      title: t("faq1Question"),
      text: t("faq1Answer"),
    },
    {
      title: t("faq2Question"),
      text: t("faq2Answer"),
    },
    {
      title: t("faq3Question"),
      text: t("faq3Answer"),
    },
    {
      title: t("faq4Question"),
      text: t("faq4Answer"),
    },
  ];

  return (
    <Container className={`p-0 ${style.pricingContainer}`} fluid>
      <Row
        className={`m-0 align-items-center justify-content-around ${style.pricingSec1}`}
      >
        <Col lg={5} className={style.pricingIntro}>
          <h1 className={style.pricingTitle}>
            {t("Find the best plan for you")}
          </h1>
          <h5 className={style.pricingSubtitle}>{t("pricingSubtitle")}</h5>
        </Col>
        <Col
          xs={12}
          sm={8}
          md={6}
          lg={4}
          xl={3}
          className={style.pricingCTA}
        ></Col>
      </Row>
      <Row
        className="m-0 px-0 justify-content-center"
        style={{ backgroundColor: "#F6F4F1" }}
      >
        <PlanSquare
          title={t("Basic")}
          price="$0"
          subtitle={t("No credit card required")}
          planInfo={t("freePlanInfo")}
          planBodyTitle={t("Features you’ll love:")}
          advantages={freeAdvantages}
        />
        <PlanSquare
          title="Pro"
          price="$5"
          subtitle={t("per month (1 person)")}
          planInfo={t("proPlanInfo")}
          planBodyTitle={t("Everything in Basic, plus:")}
          advantages={proAdvantages}
        />
      </Row>
      <Row
        className={`m-0 px-0 py-5 justify-content-center ${style.advantages} ${
          screenSize > 768 && screenSize < 992 ? "6" : "gap-3"
        }`}
      >
        <h2 className={`${style.sectionTitle} mb-4`}>
          {t("Included With Every Plan")}
        </h2>
        {paragraphs.map((p, index) => (
          <Col key={index} md={screenSize > 768 && screenSize < 992 ? 6 : 5}>
            <Row className="justify-content-center">
              <Col xs={1} className={style.checkmarkContainer}>
                <MdDone className={style.paragraphTitle} />
              </Col>
              <Col xs={10} md={7} className={style.paragraphContainer}>
                <h4 className={style.paragraphTitle}>{p.title}</h4>
                <p className={style.paragraph}>{p.text}</p>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <TextsSlider />
      <Row className={`m-0 px-0 justify-content-center  ${style.questions}`}>
        <h2 className={style.sectionTitle}>
          {t("Frequently Asked Questions")}
        </h2>
        {collapses.map((c, index) => (
          <Col key={index} md={5} lg={10}>
            <Collapse index={index} title={c.title} text={c.text} />
          </Col>
        ))}
      </Row>
      <Footer />
    </Container>
  );
};

export default Pricing;
