import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Navbar, Container, Col, Offcanvas, Button } from "react-bootstrap";
import { NavLinkWithLocale } from "../index";
import { useAppContext } from "../../context";
import imgLogo from "../../assets/BCM-Logo.png";
import { getAuth, signOut } from "firebase/auth";
import { app } from "../../firebase";
import { useScreenSize } from "../../utils";
import { DesktopMenu, HamburguerMenu } from "./index";
//import SubscriptionStatus from "../../Pages/Dashboard/Profile/SubscriptionStatusListener";
import style from "./HeaderBar.module.css";
import { FaBars } from "react-icons/fa";

const HeaderBar = () => {
  const auth = getAuth(app);
  const { user, setUser, loading, setCreateNewPaletteModal, shareMode, setColormatch, setImages } =
    useAppContext();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [sharePage, setSharePage] = useState(false);
  const [actualLocation, setActualLocation] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const screenSize = useScreenSize();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (location.pathname.startsWith(`/${i18n.language}/share`)) {
      setSharePage(true);
    } else {
      setSharePage(false);
    }
  }, [location.pathname, i18n.language]);

  const handleLogOut = async () => {
    try {
      await signOut(auth);
      setUser(null); // Limpia el estado del usuario en tu contexto
      setColormatch([]);
      setImages([]);
      localStorage.removeItem("userLoggedIn"); // Elimina la marca de sesión
      navigate(`/${i18n.language}`); // Redirige a la página principal
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const handleChangeLocation = (e) => {
    setShowOffcanvas(false);

    const currentPath = location.pathname.split("/").slice(2).join("/"); // Obtener la ruta sin el prefijo de idioma
    const newPath = `/${i18n.language}/colormatch`;

    if (currentPath !== "colormatch") {
      navigate(newPath);
    } else {
      setCreateNewPaletteModal(true);
    }
  };

  const handleNavLinkClick = () => {
    setShowOffcanvas(false);
  };

  useEffect(() => {
    //console.log("Location: ", location);
    setActualLocation(location.pathname);
  }, [location]);

  return (
    <Navbar key="md" expand="md" className={style.navbar} fixed="top">
      <Container
        fluid
        className={`align-items-center ${
          screenSize <= 768 ? style.mobile : style.desktop
        }`}
      >
        {screenSize < 992 && (
          <Col className="p-0 m-0" xs={1}>
            <Button
              variant="secondary"
              onClick={() => setShowOffcanvas(!showOffcanvas)}
              className={style.hamburgerIcon}
            >
              <FaBars size={20} />
            </Button>
          </Col>
        )}
        <Navbar.Brand className={style.brandContainer}>
          <NavLinkWithLocale to={"/"} className={style.brand}>
            <img src={imgLogo} className={style.imgLogo} alt="Logo" />{" "}
            <span>BETA</span>
          </NavLinkWithLocale>
        </Navbar.Brand>
        {(sharePage && shareMode === "internal") ||
          (!sharePage && (
            <Navbar.Offcanvas
              show={showOffcanvas}
              onHide={() => setShowOffcanvas(false)}
              id="offcanvasNavbar-expand-md"
              aria-labelledby="offcanvasNavbarLabel-expand-md"
            >
              <Offcanvas.Header closeButton className={style.offcanvasHeader}>
                {user && (
                  <Offcanvas.Title
                    id="offcanvasNavbarLabel-expand-md"
                    className={style.offcanvasTitle}
                  >
                    {t("Hi")}
                    {user && `, ${user.name} `}!
                  </Offcanvas.Title>
                )}
              </Offcanvas.Header>
              {screenSize >= 992 ? (
                <DesktopMenu
                  actualLocation={actualLocation}
                  user={user}
                  loading={loading}
                  handleChangeLocation={handleChangeLocation}
                  handleLogOut={handleLogOut}
                />
              ) : (
                showOffcanvas && (
                  <HamburguerMenu
                    actualLocation={actualLocation}
                    user={user}
                    loading={loading}
                    handleChangeLocation={handleChangeLocation}
                    handleNavLinkClick={handleNavLinkClick}
                    handleLogOut={handleLogOut}
                  />
                )
              )}
            </Navbar.Offcanvas>
          ))}
      </Container>
    </Navbar>
  );
};

export default HeaderBar;
