import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GoDownload } from "react-icons/go";
import { Button } from "react-bootstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useAppContext } from "../../context";
import { PDFClient } from "./index";
import style from "./ExportBtn.module.css";
import { FiDownload } from "react-icons/fi";

const ExportBtn = ({
  image,
  exportOption,
  sharedPalette,
  sharedPaletteObj,
  user,
  paletteName,
}) => {
  const { colormatch, images } = useAppContext();
  const [btnHover, setBtnHover] = useState();
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setBtnHover(true);
  };

  const handleMouseLeave = () => {
    setBtnHover(false);
  };

  const pdf = () => {
    if (exportOption === "client" || sharedPaletteObj?.type === "client") {
      return (
        <PDFClient
          colors={sharedPalette ? sharedPaletteObj.colors : colormatch}
          images={sharedPalette ? sharedPaletteObj.images : images}
          showImage={sharedPalette ? sharedPaletteObj.showImage : image}
          sharedPalette={sharedPalette}
          user={user}
          paletteName={paletteName}
        />
      );
    } else if (
      exportOption === "internal" ||
      sharedPaletteObj?.type === "internal"
    ) {
      return (
        /* <PDFInternal
          colors={sharedPalette ? sharedPaletteObj.colors : colormatch}
          images={sharedPalette ? sharedPaletteObj.images : images}
          showImage={sharedPalette ? sharedPaletteObj.showImage : image}
          sizes={sizes}
          sharedPalette={sharedPalette}
        /> */
        <PDFClient
          colors={sharedPalette ? sharedPaletteObj.colors : colormatch}
          images={sharedPalette ? sharedPaletteObj.images : images}
          showImage={sharedPalette ? sharedPaletteObj.showImage : image}
          sharedPalette={sharedPalette}
          user={user}
          paletteName={paletteName}
          exportOption={exportOption}
        />
      );
    }
  };

  return (
    <Button
      variant="dark"
      className={sharedPalette ? style.sharedPaletteExportBtn : style.exportBtn}
      disabled={(!exportOption || !paletteName) && !sharedPalette}
      onMouseEnter={sharedPalette && handleMouseEnter}
      onMouseLeave={sharedPalette && handleMouseLeave}
    >
      {exportOption || sharedPalette ? (
        <PDFDownloadLink
          className={`${
            sharedPalette ? style.sharedPaletteDownloadLink : style.downloadLink
          } ${btnHover && style.textWhite}`}
          document={pdf()}
          fileName={`${paletteName ? `${paletteName}.pdf` : 'palette.pdf'}`}
        >
          {({ blob, error, loading, url }) => {
            if (loading) return "Processing...";
            if (error) {
              console.log(error);
              return "Error generating";
            }
            return <>{sharedPalette && <FiDownload />}{t("Download")}</>;
          }}
        </PDFDownloadLink>
      ) : (
        <>{t("Download")}</>
      )}
    </Button>
  );
};

export default ExportBtn;
