import React, { useState } from "react";
import { IoBalloon } from "react-icons/io5";
import { BiSolidParty } from "react-icons/bi";
import { FaUser, FaHome, FaImages } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa6";
import { HamburguerBtn, NavLinkWithLocale } from "../index";
import style from "./SideBar.module.css";

const SideBar = () => {
  const [show, setShow] = useState(false);

  const handleOffcanvas = () => {
    setShow(!show);
  };

  const getLinkClassName = (isActive) => {
    return isActive
      ? `${style.activeLink} ${show ? style.expandedActiveLink : ""}`
      : `${style.links} ${show ? style.expandedLink : ""}`;
  };

  return (
    <div className={`${style.sidebar} ${show && style.expanded}`}>
      <div
        className={`${style.sidebarTop} ${show && style.sidebarTopExpanded}`}
      >
        <HamburguerBtn clicked={show} handleOffcanvas={handleOffcanvas} />
      </div>
      <NavLinkWithLocale
        to="/dashboard"
        end
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <FaHome />
        {show && <p className="m-0">Home</p>}
      </NavLinkWithLocale>
      <NavLinkWithLocale
        to="/dashboard/balloons"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <IoBalloon />
        {show && <p className="m-0">Balloons</p>}
      </NavLinkWithLocale>
      <NavLinkWithLocale
        to="/dashboard/accessories"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <BiSolidParty />
        {show && <p className="m-0">Accessories</p>}
      </NavLinkWithLocale>
      <NavLinkWithLocale
        to="/dashboard/users"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <FaUser />
        {show && <p className="m-0">Users</p>}
      </NavLinkWithLocale>
      <NavLinkWithLocale
        to="/dashboard/uploadedImages"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <FaImages />
        {show && <p className="m-0">Uploaded Images</p>}
      </NavLinkWithLocale>
      <NavLinkWithLocale
        to="/dashboard/hexDistanceCalc"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <FaCalculator />
        {show && <p className="m-0">Hex Distance Calculator</p>}
      </NavLinkWithLocale>
    </div>
  );
};

export default SideBar;
