import React from "react";
import {
  Button,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "../CardsContainer.module.css";
import { useScreenSize } from "../../../utils";
import { useAppContext } from "../../../context";

const ViewDetailsRow = ({
  item,
  selected,
  addColor,
  replaceColors,
  buttons,
  closer,
  autocomplete,
  // shared,
}) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const { user } = useAppContext();
  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const formatSize = (size) => {
    const formattedSize = size.replace(/size/g, ""); // Elimina 'size'
    const [baseSize, suffix] = formattedSize.split("_"); // Divide en base y sufijo
    return {
      baseSize: parseInt(baseSize, 10), // Convierte baseSize a número
      suffix: suffix ? parseInt(suffix, 10) : null, // Convierte sufijo a número si existe
      original: formattedSize, // Mantén el formato original para reconstruir
    };
  };

  const itemSizes = Object.keys(item).filter((prop) => prop.includes("size"));

  const sortedSizes = itemSizes
    .map(formatSize) // Aplica el formato a cada size
    .sort((a, b) => {
      if (a.suffix === null && b.suffix === null) {
        return a.baseSize - b.baseSize; // Ordena por baseSize si no tienen sufijo
      } else if (a.suffix === null) {
        return -1; // a sin sufijo va antes que b con sufijo
      } else if (b.suffix === null) {
        return 1; // b sin sufijo va antes que a con sufijo
      } else {
        return a.baseSize - b.baseSize || a.suffix - b.suffix; // Ordena por baseSize y luego por sufijo
      }
    })
    .map(({ original }) => original); // Recupera el formato original

  return (
    <Row
      className={`${style.cardRow} ${
        autocomplete && style.autocompleteCardRow
      } ${!userPro && style.bluredRow}`}
    >
      <Col xs={3} lg={1} className="m-0 p-0">
        <Image
          src={item.image ? item.image[0].imageURL : item.imageURL}
          style={{ aspectRatio: "4/5" }}
          width="100%"
          alt="balloonImg"
        />
        <p className={style.displayName}>
          {item.displayName.startsWith("http") ? (
            <Image
              src={`${item.displayName}`}
              width={"100%"}
              alt="displayName"
            />
          ) : (
            item.displayName || "-"
          )}
        </p>
      </Col>
      <Col xs={9} lg={11}>
        <Row className={`justify-content-center`}>
          {/* <Col xs={5} md={4} lg={1}>
            <small>{item.color}</small>
            <div style={{backgroundColor: item.color, width: '50px', height: '20px'}}></div>
            </Col> */}
          <Col
            xs={12}
            md={4}
            lg={5}
            className={`${screenSize >= 992 && "ms-1"}`}
          >
            <h3 className={style.tableTitle}>{t("BRAND & COLOR NAME")}</h3>
            {item.combined ? (
              <>
                <h5 className={style.secondaryTitle}>{t("OUTSIDE/INSIDE")}</h5>
                {item.combination ? (
                  <Image
                    width={"100%"}
                    src={item.combination}
                    alt="outsideBrand"
                  />
                ) : (
                  <p className={style.dataBrandColorName}>
                    {item.brand.split("/")[0]} - {item.outsideColor} /{" "}
                    {item.brand.split("/")[1]} - {item.insideColor}
                  </p>
                )}
              </>
            ) : item.brandName ? (
              <Image src={item.brandName} width={"70%"} />
            ) : (
              <p className={style.dataBrandColorName}>
                {item.brand} - {item.name}
              </p>
            )}
            <small>{item.distance}</small>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <h3 className={style.tableTitle}>{t("AVAILABLE SIZES")}</h3>
            <Row style={{ paddingLeft: ".3rem" }}>
              {sortedSizes.map((size) => {
                const underscore = size.split("_")[0];
                if (selected || (!selected && userPro) || autocomplete) {
                  return (
                    item[`size${size}`] && (
                      <Col
                        key={size}
                        xs={"auto"}
                        className={`${
                          screenSize <= 992
                            ? style.sizesMobile
                            : style.sizesDesktop
                        }`}
                      >
                        <h3 className={style.dataSizes}>
                          {size.includes("_")
                            ? `${underscore}" LINK`
                            : size > 100
                            ? `${size}`
                            : `${size}"`}
                        </h3>
                      </Col>
                    )
                  );
                } else if (!userPro) {
                  return (
                    item[`size${size}`] && (
                      <Col key={size} xs="auto">
                        <Image src={item[`size${size}`]} alt="bluredSize" />
                      </Col>
                    )
                  );
                }
              })}
            </Row>
          </Col>
          <>
            <Col
              xs={12}
              lg={3}
              className={`${style.rowBtn} ${
                screenSize >= 992 && "ms-4 mt-5 align-items-end"
              }`}
            >
              {(!selected && buttons) || (selected && !closer && buttons) ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        className={style.infoTooltip}
                        style={{ zIndex: "200000" }}
                      >
                        {t("Replace with this balloon")}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => (!userPro ? null : replaceColors(item))}
                      disabled={!userPro}
                      className={style.actionBtn}
                      style={screenSize >= 992 ? { width: "130px" } : {}}
                    >
                      {t("Replace")}
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip
                        className={style.infoTooltip}
                        style={{ zIndex: "200000" }}
                      >
                        {t("Add this balloon to palette")}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => (!userPro ? null : addColor(item))}
                      disabled={!userPro}
                      className={style.actionBtn}
                      style={screenSize >= 992 ? { width: "130px" } : {}}
                    >
                      {t("Add to Palette")}
                    </Button>
                  </OverlayTrigger>
                </>
              ) : null}
            </Col>
          </>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewDetailsRow;
