import React, { useCallback, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ModalWindow,
  AddElementModalContent,
  CustomModalContent,
  Filters,
} from "../index";
import {
  Alert,
  colorMatchFn,
  useAccessoriesByCategory,
  useScreenSize,
} from "../../utils";
import { useAppContext } from "../../context";
import style from "./Add.module.css";

const ModalRenderer = ({ category, modalState, closeModal }) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const { colormatch, setColormatch, user, setLoadingColorMatch } = useAppContext();
  const { data: accessoryCategory, isLoading: loadingAccessories } =
    useAccessoriesByCategory(category);
  const [selected, setSelected] = useState([]);
  const [customImg, setCustomImage] = useState({
    imageURL: null,
    name: "",
  });
  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const handleColorMatch = useCallback(
    (elementSelected) => {
      colorMatchFn(colormatch, setColormatch, setLoadingColorMatch, elementSelected, userPro);
    },
    [colormatch, setColormatch, userPro, setLoadingColorMatch]
  );

  const handleAdd = () => {
    if (modalState.balloon) {
      selected.forEach((item) => {
        handleColorMatch(item.color);
      })
    } else if (modalState.custom) {
      if (customImg.name === "") return Alert.error("Please provide a title.");
      setColormatch((prevState) => [...prevState, customImg]);
    } else {
      const accessoriesSelected = selected.map((item) => ({
        id: item.id,
        name: item.name,
        hexColor: item.hexColor,
        imageURL: item.imageURL,
      }));
      setColormatch((prevState) => [...prevState, ...accessoriesSelected]);
    }
    setSelected([]);
    setCustomImage({
      imageURL: null,
      name: "",
      custom: true,
    });
    closeModal();
    
  };

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    rejectedFiles.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          Alert.error(
            "Format not accepted. Accepts .jpg, .jpeg, .png, .webp, and .gif file types."
          );
        }
      });
    });
    const imgSrc = URL.createObjectURL(acceptedFiles[0]);
    setCustomImage({ ...customImg, imageURL: imgSrc });
  };

  if (modalState.balloon) {
    return (
      <ModalWindow
        className={style.modalFullscreenCustom}
        show={modalState.balloon}
        onHide={closeModal}
        title={t("Latex Balloons")}
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <Filters
            selected={selected}
            setSelected={setSelected}
            addBalloonsModal={true}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={selected.length === 0}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else if (modalState.foilShape) {
    return (
      <ModalWindow
        show={modalState.foilShape}
        onHide={closeModal}
        title={t("Foil Shape Balloons")}
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <AddElementModalContent
            title={t("Foil Shape Balloons")}
            options={accessoryCategory}
            loading={loadingAccessories}
            selected={selected}
            setSelected={setSelected}
            placeholder={t("Search...")}
            renderOption={(option) => (
              <Row className="justify-content-center">
                <Col xs={10}>
                  <h4 className={style.optionTitle}>{option.name}</h4>
                </Col>
              </Row>
            )}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={selected.length === 0}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else if (modalState.foilNumberLetter) {
    return (
      <ModalWindow
        show={modalState.foilNumberLetter}
        onHide={closeModal}
        title={t("Foil Number & Letter Balloons")}
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <AddElementModalContent
            title={t("Foil Number & Letter Balloons")}
            options={accessoryCategory}
            loading={loadingAccessories}
            selected={selected}
            setSelected={setSelected}
            placeholder={t("Search...")}
            renderOption={(option) => (
              <Row className="justify-content-center">
                <Col xs={10}>
                  <h4 className={style.optionTitle}>{option.name}</h4>
                </Col>
              </Row>
            )}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={selected.length === 0}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else if (modalState.confetti) {
    return (
      <ModalWindow
        show={modalState.confetti}
        onHide={closeModal}
        title="Confetti"
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <AddElementModalContent
            title="Confetti"
            options={accessoryCategory}
            loading={loadingAccessories}
            selected={selected}
            setSelected={setSelected}
            placeholder={t("Search...")}
            renderOption={(option) => (
              <Row className="justify-content-center">
                <Col xs={10}>
                  <h4 className={style.optionTitle}>{option.name}</h4>
                </Col>
              </Row>
            )}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={selected.length === 0}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else if (modalState.tassel) {
    return (
      <ModalWindow
        show={modalState.tassel}
        onHide={closeModal}
        title={t("Tassels")}
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <AddElementModalContent
            title={t("Tassels")}
            options={accessoryCategory}
            loading={loadingAccessories}
            selected={selected}
            setSelected={setSelected}
            placeholder={t("Search...")}
            renderOption={(option) => (
              <Row className="justify-content-center">
                <Col xs={10}>
                  <h4 className={style.optionTitle}>{option.name}</h4>
                </Col>
              </Row>
            )}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={selected.length === 0}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else if (modalState.backdropPanel) {
    return (
      <ModalWindow
        show={modalState.backdropPanel}
        onHide={closeModal}
        title={t("Backdrop/Panel Colors")}
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <AddElementModalContent
            title={t("Backdrop/Panel Colors")}
            options={accessoryCategory}
            loading={loadingAccessories}
            selected={selected}
            setSelected={setSelected}
            placeholder={t("Search...")}
            renderOption={(option) => (
              <Row className="justify-content-center">
                <Col xs={10}>
                  <h4 className={style.optionTitle}>{option.name}</h4>
                </Col>
              </Row>
            )}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={selected.length === 0}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else if (modalState.floralGreenery) {
    return (
      <ModalWindow
        show={modalState.floralGreenery}
        onHide={closeModal}
        title={t("Greenery & Florals")}
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <AddElementModalContent
            title={t("Greenery & Florals")}
            options={accessoryCategory}
            loading={loadingAccessories}
            selected={selected}
            setSelected={setSelected}
            placeholder={t("Search...")}
            renderOption={(option) => (
              <Row className="justify-content-center">
                <Col xs={10}>
                  <h4 className={style.optionTitle}>{option.name}</h4>
                </Col>
              </Row>
            )}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={selected.length === 0}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else if (modalState.custom) {
    return (
      <ModalWindow
        show={modalState.custom}
        onHide={closeModal}
        title={t("Custom Upload")}
        closeButton={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        addModal={true}
        body={
          <CustomModalContent
            customImg={customImg}
            handleDrop={handleDrop}
            setCustomImage={setCustomImage}
          />
        }
        footer={
          <Button
            onClick={handleAdd}
            className={style.addBtn}
            disabled={customImg.imageURL ? false : true}
          >
            {t("Add to Palette")}
          </Button>
        }
      />
    );
  } else {
    return null;
  }
};

export default ModalRenderer;
