import React, { useMemo, useState } from "react";
import { MdOutlineSearch } from "react-icons/md";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  FilterByBrand,
  FilterByCategory,
  FilterBySize,
  Inputs,
  SearchColorCard,
} from "../index";
import {
  useBalloonsV2,
  useFilterByBrand,
  useFilterByCategory,
  useSizeFilter,
} from "../../utils";
import style from "./SearchTool.module.css";

const SearchTool = () => {
  const [search, setSearch] = useState("");
  const [showBalloons, setShowBalloons] = useState(false);
  const { data: balloonsData, isLoading: loadingBalloons } = useBalloonsV2();
  const filteredBalloons = balloonsData?.filter((balloon) => {
    const normalizedSearch = search.toLowerCase();

    // Normaliza los valores a comparar
    const nameMatch = balloon.name.toLowerCase().includes(normalizedSearch);
    const displayNameMatch = balloon.displayName
      .toLowerCase()
      .includes(normalizedSearch);

    // Compara cada categoría con el término de búsqueda
    const colorCategoriesMatch = balloon.colorCategories.some((category) =>
      category.toLowerCase().includes(normalizedSearch)
    );

    return nameMatch || displayNameMatch || colorCategoriesMatch;
  });

  const { brandsSelected, handleBrandsChange, filteredBrands } =
    useFilterByBrand(filteredBalloons);
  const { sizesSelected, handleSizesChange, filteredSizes } =
    useSizeFilter(filteredBalloons);
  const { categoriesSelected, handleCategoryChange, filteredCategories } =
    useFilterByCategory(filteredBalloons);

  const options = useMemo(() => {
    let result = filteredBalloons;

    // Aplicar filtro de marcas si se ha filtrado algo
    if (Object.values(brandsSelected).some((selected) => !selected)) {
      result = filteredBrands;
    }

    // Aplicar filtro de tamaños si se ha filtrado algo
    if (Object.values(sizesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredSizes.includes(item));
    }

    // Aplicar filtro de categorías si se ha filtrado algo
    if (Object.values(categoriesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredCategories.includes(item));
    }

    return result;
  }, [
    filteredBalloons,
    brandsSelected,
    filteredBrands,
    sizesSelected,
    filteredSizes,
    categoriesSelected,
    filteredCategories,
  ]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setShowBalloons(false);
  };

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col xs={10}>
          <Inputs.FormControl
            value={search}
            onChange={handleSearch}
            label="Search a Balloon: Your go-to reference tool for balloon colors"
            customClass={style.searchBarLabel}
            icon={<MdOutlineSearch />}
            placeholder="Search Balloons..."
            decorationBtn
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setShowBalloons(true);
              }
            }}
            decoration={
              <Button
                variant="dark"
                className={style.searchBtn}
                onClick={() => setShowBalloons(true)}
              >
                Search
              </Button>
            }
          />
          {!loadingBalloons && search !== "" && (
            <p className={`mt-4 ${style.resultsCount}`}>
              {options.length} Results found for "{search}"
            </p>
          )}
        </Col>
        <Col xs={12}>
          {!loadingBalloons && search !== "" && showBalloons && (
            <Row>
              <Col xs="auto">
                <FilterByBrand
                  brandsSelected={brandsSelected}
                  handleChange={handleBrandsChange}
                />
              </Col>
              <Col xs="auto">
                <FilterBySize
                  sizesSelected={sizesSelected}
                  handleChange={handleSizesChange}
                />
              </Col>
              <Col xs="auto">
                <FilterByCategory
                  categoriesSelected={categoriesSelected}
                  handleChange={handleCategoryChange}
                />
              </Col>
            </Row>
          )}
          {showBalloons && (
            <Row>
              {!loadingBalloons &&
                search !== "" &&
                options.map((balloon) => (
                  <Col key={balloon.id} xs={6} md={4} lg={2}>
                    <SearchColorCard key={balloon.color} balloon={balloon} />
                  </Col>
                ))}
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SearchTool;
