import React from "react";
import { Col, Nav, Offcanvas, Spinner } from "react-bootstrap";
import style from "./HeaderBar.module.css";
import { LanguageSwitcher, NavLinkWithLocale } from "../index";
import { useTranslation } from "react-i18next";

const HamburguerMenu = ({
  user,
  loading,
  handleChangeLocation,
  handleLogOut,
  handleNavLinkClick,
  actualLocation,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Offcanvas.Body className={style.offcanvasBody}>
      <Nav justify className="flex-column">
        {loading ? (
          <Col sm={1}>
            <Spinner animation="grow" size="sm" variant="dark" />
          </Col>
        ) : user ? (
          <>
            <Nav.Link
              className={`${style.mobileLinks}`}
              onClick={handleChangeLocation}
            >
              {actualLocation === `/${i18n.language}/colormatch`
                ? t("Create New Palette")
                : t("My Palette")}
            </Nav.Link>
            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/profile/palettes"
            >
              {t("Saved palettes")}
            </NavLinkWithLocale>
            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/profile"
            >
              {t("Account settings")}
            </NavLinkWithLocale>
          </>
        ) : (
          <>
            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/createAccount"
              >
              {t("Sign Up")}
            </NavLinkWithLocale>

            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/login"
            >
              {t("Log In")}
            </NavLinkWithLocale>
          </>
        )}

        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={`${style.mobileLinks}`}
          to="/product"
          >
          {t("Pricing")}
        </NavLinkWithLocale>

        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={`${style.mobileLinks}`}
          to="/"
          >
          {t("Home")}
        </NavLinkWithLocale>

        {user && user.role === "1" && (
          <NavLinkWithLocale
            onClick={handleNavLinkClick}
            className={`${style.mobileLinks}`}
            to="/dashboard"
          >
            Dashboard
          </NavLinkWithLocale>
        )}

        <LanguageSwitcher mobile={true} position="mobile" />

        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/terms"
        >
          {t("Terms")}
        </NavLinkWithLocale>

        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/privacyPolicy"
          >
          {t("Privacy Policy")}
        </NavLinkWithLocale>

        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/contactUs"
          >
          {t("Contact Us")}
        </NavLinkWithLocale>
        <hr />
        {user &&
          <NavLinkWithLocale
            onClick={handleLogOut}
            className={`${style.mobileLinks}`}
          >
            {t("Log out")}
          </NavLinkWithLocale>
        }
      </Nav>
    </Offcanvas.Body>
  );
};

export default HamburguerMenu;
