import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiFolderSharedLine } from "react-icons/ri";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useAppContext } from "../../../context";
import SaveContent from "./SaveContent";
import style from "./Save.module.css";

const SaveBtn = ({ overlay, setOverlay }) => {
  const { t } = useTranslation();
  const { colormatch } = useAppContext();
  const [savedPalette, setSavedPalette] = useState(false);

  const popover = (
    <Popover id="popover-save">
      <Popover.Body>
        <SaveContent window={overlay} setSavedPalette={setSavedPalette} />
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      {...(savedPalette && { show: false })}
      trigger="click"
      placement="bottom"
      overlay={popover}
      rootClose
      onExit={() =>
        setOverlay((prevState) => ({
          ...prevState,
          save: false,
        }))
      }
    >
      <Button
        variant="secondary"
        className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
        disabled={colormatch.length > 0 ? false : true}
        onClick={() =>
          setOverlay((prevState) => ({
            ...prevState,
            save: true,
          }))
        }
      >
        <RiFolderSharedLine />
        {t("Save")}
      </Button>
    </OverlayTrigger>
  );
};

export default SaveBtn;
