import React, { Fragment, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ModalWindow, ViewDetailsRow } from "../../index";
import { colorMatchFn, useScreenSize } from "../../../utils";
import style from "./SearchColorCard.module.css";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context";

const SearchColorCard = ({ balloon }) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const { colormatch, setColormatch, user, setLoadingColorMatch } = useAppContext();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showSecondariesModal, setShowSecondariesModal] = useState(false);
  const [matchRanges, setMatchRanges] = useState({});

  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const closerBalloonsByRanges = async () => {
    const ranges = await colorMatchFn(
      colormatch,
      setColormatch,
      setLoadingColorMatch,
      balloon.color,
      userPro,
      { search: true }
    );
    const {
      primaryMatchRange,
      secondMatchRange,
      thirdMatchRange,
      fourthMatchRange,
      fifthMatchRange,
    } = ranges;

    setMatchRanges({
      primaryMatchRange,
      secondMatchRange,
      thirdMatchRange,
      fourthMatchRange,
      fifthMatchRange,
    });
    setShowSecondariesModal(true);
  };

  const rangesArray = [
    { label: "0-2", key: "primaryMatchRange" },
    { label: "2-5", key: "secondMatchRange" },
    { label: "5-10", key: "thirdMatchRange" },
    { label: "10-15", key: "fourthMatchRange" },
    { label: "15-20", key: "fifthMatchRange" },
  ];

  return (
    <Card className={style.card}>
      <Card.Img
        src={balloon.image ? balloon.image[0].imageURL : balloon.imageURL}
        alt={`balloon-${balloon.name}`}
        className={style.cardImg}
      />
      <Card.Body className="px-0 py-2">
        <Card.Title className={style.cardTitle}>{balloon.name}</Card.Title>
        <Card.Text className={`mb-1 ${style.cardText}`}>
          {balloon.brand}
        </Card.Text>
        <Button
          variant="light"
          className={style.cardBtn}
          onClick={() => setShowDetailModal(true)}
        >
          VIEW INFO
        </Button>
        <Button
          variant="light"
          className={style.cardBtn}
          onClick={closerBalloonsByRanges}
        >
          FIND ALTERNATES
        </Button>
      </Card.Body>
      {
        <ModalWindow
          show={showDetailModal}
          onHide={() => setShowDetailModal(false)}
          closeButton={true}
          centered={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          title={`${t("Details")}:`}
          viewDetailsModal={true}
          id="view-detail-modal"
          body={<ViewDetailsRow item={balloon} />}
        />
      }
      {
        <ModalWindow
          show={showSecondariesModal}
          onHide={() => setShowSecondariesModal(false)}
          closeButton={true}
          centered={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          title={`${t("Selected")}:`}
          viewDetailsModal={true}
          id="view-more-modal"
          body={
            <Row>
              <Col className={`p-0 ${style.infoContainer}`}>
                {rangesArray.map(
                  ({ label, key }) =>
                    matchRanges[key]?.length > 0 && (
                      <Fragment key={key}>
                        <hr />
                        <p className={`${style.title} px-2 mb-2`}>{label}</p>
                        {matchRanges[key].map((item, index) => (
                          <ViewDetailsRow
                            key={index}
                            item={item}
                            index={index}
                          />
                        ))}
                      </Fragment>
                    )
                )}
              </Col>
            </Row>
          }
        />
      }
    </Card>
  );
};

export default SearchColorCard;
